import React from 'react'
import { OButton, OCol, ORow } from '@dnvgl-onefoundation/onedesign-react'
import { useProvider } from '@/hooks'
import { ContentSection, Page, SpinIndicator } from '@/components/layout'
import {
  BreadcrumbsTitle,
  CoordinatesValue,
  Input,
  ProviderPin,
  ProviderToggle,
  SaveButton,
  StatusIndicator,
  TooltipHelpText
} from '@/components/helpers'
import { MapBoxWrapper } from '@/components/maps'
import {
  helper,
  config,
  enumFlags,
  tooltips,
  status as statusUtils
} from '@/utils'
import { ProviderStatus } from '@/interfaces'
import { Button } from '@mui/joy'
import IconComponent from '@/components/helpers/IconComponent'
import { Grid2 } from '@mui/material'

const { generateYearOptions, toHtmlDate } = helper
const { urls, icons } = config
const yearOptions = generateYearOptions(1950)
const {
  providerTypeNames,
  providerMaterialsEnumNames,
  providerServiceNames,
  total
} = enumFlags
const { getStatusLabel } = statusUtils

const ProviderPage = () => {
  const {
    isLoading,
    provider,
    providerTypes,
    materials,
    services,
    countryOptions,
    lngLat,
    iconClass,
    pinColor,
    isEditable,
    setAddress,
    onUpdate,
    onCountryChange,
    onEnumUpdate,
    onLocationUpdate,
    onRename,
    onRemove,
    onSave,
    onSaveAndClose,
    onSaveToDrafts,
    onSetCoordinatesByAddress
  } = useProvider()
  return (
    <Page
      title={
        provider?.name ? (
          <BreadcrumbsTitle
            disabled={isLoading}
            isDraft={provider?.isDraft}
            title={provider?.name}
            backLink={urls.providers}
            backName="Providers"
          />
        ) : null
      }
      isH1Title={false}
      actions={
        isEditable &&
        !isLoading &&
        provider?.name && (
          <Grid2 container gap="4px">
            <Button
              disabled={isLoading}
              onClick={onRemove}
              variant="plain"
              startDecorator={
                <IconComponent iconClass="fas fa-trash text-danger" size={20} />
              }>
              Remove
            </Button>
            <Button
              disabled={isLoading}
              onClick={onRename}
              variant="plain"
              startDecorator={
                <IconComponent iconClass="fas fa-pen" size={20} />
              }>
              Rename
            </Button>
            <SaveButton
              onSave={onSave}
              onSaveAndClose={onSaveAndClose}
              onSaveToDrafts={onSaveToDrafts}
            />
          </Grid2>
        )
      }>
      <ORow>
        {isLoading ? (
          <OCol md="12">
            <SpinIndicator />
          </OCol>
        ) : provider?.name ? (
          <>
            <OCol md="7">
              <ContentSection
                title="Location and contact information"
                actions={
                  isEditable && (
                    <OButton
                      variant="subtle"
                      size="small"
                      iconClass={icons.marker}
                      onClick={onSetCoordinatesByAddress}>
                      Set coordinates by address
                    </OButton>
                  )
                }
                padding="0"
                icon="fas fa-map-pin"
                contentClassName="p-0"
                underlineTitle>
                <ORow className="p-3">
                  <OCol md="6">
                    <Input
                      propertyName="countryId"
                      label="Location"
                      value={provider?.countryId}
                      showOnlyValue={!isEditable}
                      type="select"
                      options={countryOptions}
                      onChange={(_n, v) => onCountryChange(v)}
                    />
                  </OCol>
                  <OCol md="6">
                    <Input
                      propertyName="address"
                      label="Facility address"
                      defaultValue={provider?.address}
                      showOnlyValue={!isEditable}
                      onBlur={(n, v) => {
                        setAddress(v)
                        onUpdate(n, v)
                      }}
                    />
                  </OCol>
                  <OCol md="6">
                    <CoordinatesValue
                      isEditable={isEditable}
                      lngLat={lngLat}
                      onLocationUpdate={onLocationUpdate}
                    />
                  </OCol>
                  <OCol md="6">
                    <Input
                      propertyName="hqAddress"
                      label={
                        <>
                          HQ Address&nbsp;
                          <TooltipHelpText
                            message={tooltips.providers.hqAddress}
                          />
                        </>
                      }
                      defaultValue={provider?.hqAddress}
                      showOnlyValue={!isEditable}
                      onBlur={(n, v) => onUpdate(n, v)}
                    />
                  </OCol>
                  <OCol md="6">
                    <Input
                      propertyName="hqCountryId"
                      label="HQ Country"
                      value={provider?.hqCountryId}
                      type="select"
                      options={countryOptions}
                      showOnlyValue={!isEditable}
                      onChange={(n, v) => onUpdate(n, v)}
                    />
                  </OCol>
                  <OCol md="6">
                    <Input
                      propertyName="phoneNumber"
                      label="Phone number"
                      defaultValue={provider?.phoneNumber}
                      showOnlyValue={!isEditable}
                      onBlur={(n, v) => onUpdate(n, v)}
                    />
                  </OCol>
                  <OCol md="6">
                    <Input
                      propertyName="websiteAddress"
                      label="Website"
                      defaultValue={provider?.websiteAddress}
                      showOnlyValue={!isEditable}
                      onBlur={(n, v) => onUpdate(n, v)}
                    />
                  </OCol>
                  <OCol md="6">
                    <Input
                      propertyName="email"
                      label="Email address"
                      defaultValue={provider?.email}
                      showOnlyValue={!isEditable}
                      onBlur={(n, v) => onUpdate(n, v)}
                    />
                  </OCol>
                </ORow>
                <MapBoxWrapper
                  height={205}
                  latitude={lngLat.lat}
                  longitude={lngLat.lng}
                  zoom={4}>
                  <ProviderPin
                    draggable={provider?.isDraft}
                    latitude={lngLat.lat}
                    longitude={lngLat.lng}
                    iconClass={iconClass}
                    isDraft={provider?.isDraft}
                    color={pinColor}
                    onDragEnd={onLocationUpdate}
                  />
                </MapBoxWrapper>
              </ContentSection>
              <ContentSection title="Details" underlineTitle>
                <ORow className="p-2">
                  <OCol md="6">
                    <div>
                      <label>
                        Operation status&nbsp;
                        <TooltipHelpText
                          message={tooltips.providers.operationStatus}
                        />
                      </label>
                    </div>
                    {isEditable ? (
                      <>
                        <OButton
                          variant={
                            provider?.status === ProviderStatus.Operating
                              ? 'secondary'
                              : 'subtle'
                          }
                          onClick={() =>
                            onUpdate?.('status', ProviderStatus.Operating)
                          }>
                          {getStatusLabel(ProviderStatus.Operating)}&nbsp;
                          <StatusIndicator status={ProviderStatus.Operating} />
                        </OButton>
                        <OButton
                          variant={
                            provider?.status ===
                            ProviderStatus.UnderConstruction
                              ? 'secondary'
                              : 'subtle'
                          }
                          onClick={() =>
                            onUpdate?.(
                              'status',
                              ProviderStatus.UnderConstruction
                            )
                          }>
                          {getStatusLabel(ProviderStatus.UnderConstruction)}
                          &nbsp;
                          <StatusIndicator
                            status={ProviderStatus.UnderConstruction}
                          />
                        </OButton>
                      </>
                    ) : (
                      <div className="mt-1">
                        {getStatusLabel(provider?.status)}
                      </div>
                    )}
                  </OCol>
                  <OCol md="6">
                    {!!provider?.operationalSinceYear && (
                      <Input
                        propertyName="operationalSinceYear"
                        label={`Operational ${
                          provider?.status === ProviderStatus.Operating
                            ? 'since'
                            : 'from'
                        }`}
                        type="select"
                        defaultValue={toHtmlDate(
                          `${provider?.operationalSinceYear}`
                        )}
                        options={yearOptions}
                        showOnlyValue={!isEditable}
                        onChange={(n, v) => onUpdate(n, v)}
                      />
                    )}
                  </OCol>
                  <OCol md="12">
                    {isEditable ? (
                      <Input
                        isTextarea
                        propertyName="processDescription"
                        label={
                          <>
                            Process description&nbsp;
                            <TooltipHelpText
                              message={tooltips.providers.processDescription}
                            />
                          </>
                        }
                        defaultValue={provider?.processDescription}
                        showOnlyValue={!isEditable}
                        onBlur={(n, v) => onUpdate(n, v)}
                      />
                    ) : (
                      <div>
                        <label>
                          {
                            <>
                              Process description&nbsp;
                              <TooltipHelpText
                                message={tooltips.providers.processDescription}
                              />
                            </>
                          }
                        </label>
                        <div className="mb-3">
                          {provider?.processDescription
                            ?.split?.('\n')
                            ?.map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </div>
                      </div>
                    )}
                  </OCol>
                  <OCol md="6">
                    <Input
                      propertyName="serviceCapacityTDay"
                      label={
                        <>
                          Capacity (tonne/day)&nbsp;
                          <TooltipHelpText
                            message={tooltips.providers.capacity}
                          />
                        </>
                      }
                      defaultValue={provider?.serviceCapacityTDay}
                      type="number"
                      showOnlyValue={!isEditable}
                      onBlur={(n, v) => onUpdate(n, parseInt(v))}
                    />
                  </OCol>
                  <OCol md="6">
                    <Input
                      propertyName="priceBladeProcessingEuroT"
                      label={
                        <>
                          Price for blade processing (€/tonne)&nbsp;
                          <TooltipHelpText
                            message={tooltips.providers.priceBladeProcessing}
                          />
                        </>
                      }
                      type="number"
                      min={0}
                      defaultValue={provider?.priceBladeProcessingEuroT}
                      showOnlyValue={!isEditable}
                      onBlur={(n, v) => onUpdate(n, v)}
                    />
                  </OCol>
                  <OCol md="6">
                    <Input
                      propertyName="commercialPartners"
                      label={
                        <>
                          Commercial partners&nbsp;
                          <TooltipHelpText
                            message={tooltips.providers.priceBladeProcessing}
                          />
                        </>
                      }
                      defaultValue={provider?.commercialPartners}
                      showOnlyValue={!isEditable}
                      onBlur={(n, v) => onUpdate(n, v)}
                    />
                  </OCol>
                  <OCol md="6">
                    <Input
                      propertyName="certification"
                      label={
                        <>
                          Certification(s)&nbsp;
                          <TooltipHelpText
                            message={tooltips.providers.certification}
                          />
                        </>
                      }
                      defaultValue={provider?.certification}
                      showOnlyValue={!isEditable}
                      onBlur={(n, v) => onUpdate(n, v)}
                    />
                  </OCol>
                </ORow>
              </ContentSection>
            </OCol>
            <OCol md="5">
              <ContentSection
                title={
                  <>
                    Facility type&nbsp;
                    <TooltipHelpText
                      message={tooltips.providers.facilityType}
                      placement="bottom"
                    />
                  </>
                }
                className="card-dnvgl-overflow"
                underlineTitle>
                <ORow className="p-2">
                  <OCol md="9">
                    {Object.keys(providerTypeNames).map(i => (
                      <ProviderToggle
                        key={i}
                        isEditable={isEditable}
                        isChecked={providerTypes.includes(parseInt(i))}
                        name={(providerTypeNames as any)?.[i]}
                        tooltip={
                          (tooltips.providers as any)?.[
                            (providerTypeNames as any)?.[i]
                          ]
                        }
                        onEnumUpdate={() =>
                          onEnumUpdate?.(
                            'providerTypes',
                            providerTypes,
                            parseInt(i)
                          )
                        }
                      />
                    ))}
                  </OCol>
                  <OCol md="3" className="text-right">
                    {isEditable && (
                      <OButton
                        variant="subtle"
                        size="small"
                        onClick={() => onUpdate('providerTypes', total.types)}>
                        Select all
                      </OButton>
                    )}
                  </OCol>
                </ORow>
              </ContentSection>
              <ContentSection
                title={
                  <>
                    Materials&nbsp;
                    <TooltipHelpText message={tooltips.providers.materials} />
                  </>
                }
                className="card-dnvgl-overflow"
                underlineTitle>
                <ORow className="p-2">
                  <OCol md="9">
                    {Object.keys(providerMaterialsEnumNames).map(i => (
                      <ProviderToggle
                        key={i}
                        isEditable={isEditable}
                        isChecked={materials.includes(parseInt(i))}
                        name={(providerMaterialsEnumNames as any)?.[i]}
                        tooltip={
                          (tooltips.providers as any)?.[
                            (providerMaterialsEnumNames as any)?.[i]
                          ]
                        }
                        onEnumUpdate={() =>
                          onEnumUpdate?.('materials', materials, parseInt(i))
                        }
                      />
                    ))}
                  </OCol>
                  <OCol md="3" className="text-right">
                    {isEditable && (
                      <OButton
                        variant="subtle"
                        size="small"
                        onClick={() => onUpdate('materials', total.materials)}>
                        Select all
                      </OButton>
                    )}
                  </OCol>
                </ORow>
              </ContentSection>
              <ContentSection
                title={
                  <>
                    Services&nbsp;
                    <TooltipHelpText message={tooltips.providers.services} />
                  </>
                }
                className="card-dnvgl-overflow"
                underlineTitle>
                <ORow className="p-2">
                  <OCol md="9">
                    {Object.keys(providerServiceNames).map(i => (
                      <ProviderToggle
                        key={i}
                        isEditable={isEditable}
                        isChecked={services.includes(parseInt(i))}
                        name={(providerServiceNames as any)?.[i]}
                        tooltip={
                          (tooltips.providers as any)?.[
                            (providerServiceNames as any)?.[i]
                          ]
                        }
                        onEnumUpdate={() =>
                          onEnumUpdate?.('services', services, parseInt(i))
                        }
                      />
                    ))}
                  </OCol>
                  <OCol md="3" className="text-right">
                    {isEditable && (
                      <OButton
                        variant="subtle"
                        size="small"
                        onClick={() => onUpdate('services', total.services)}>
                        Select all
                      </OButton>
                    )}
                  </OCol>
                </ORow>
              </ContentSection>
            </OCol>
          </>
        ) : null}
      </ORow>
    </Page>
  )
}

export default React.memo(ProviderPage)
